<script setup lang="ts">
import { ref } from 'vue';
import UiFormAlert from './Alert.vue';

const visibleError = ref('');
const timer = ref<number | null>(null);

function setError(error: string) {
	visibleError.value = error;
	timer.value = setTimeout(() => {
		visibleError.value = '';
	}, 2000);
}

function addError(error: string) {
	if (timer.value) {
		clearTimeout(timer.value);
		visibleError.value = '';
	}
	timer.value = setTimeout(() => {
		setError(error);
	}, 200);
}

defineExpose({
	addError,
});
</script>

<template>
	<Transition
		name="alert-service-error"
		mode="out-in"
	>
		<UiFormAlert
			v-if="visibleError"
			:key="visibleError"
			:text="visibleError"
		/>
	</Transition>
</template>

<style scoped>
.alert-service-error-enter-active {
  transition: all 0.3s ease-out;
}

.alert-service-error-leave-active {
  transition: all 0.5s ease-out;
}

.alert-service-error-enter-from,
.alert-service-error-leave-to {
  transform: translateY(-40px);
  opacity: 0;
}
</style>
